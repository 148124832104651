* {
  margin: 0;
  padding: 0;
  outline: none;
}

.ReactModal__Overlay {
  z-index: 2000 !important;
}

.ril__inner:after {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  pointer-events: none;
  background: url("https://admin.nudebar.ru/watermark_mini.png");
}

.zoom {
  width: 100%;
  background: #222222;
  height: 800px;
  position: relative;
  overflow: hidden;
}

.zoom img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform-style: preserve-3d;
  object-fit: contain;
}

.zoom-transition {
  transition: transform ease 50ms;
}

@media (max-width: 1000px) {
    body {
      display: none;
    }
}